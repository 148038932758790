$(document).ready(function(){
  $("#input-factory").on("keyup", function() {
    var value = $(this).val().toLowerCase();
    $("#aggs-factory li").filter(function() {
      $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
    });
  });
});

$(document).ready(function(){
  $("#input-description").on("keyup", function() {
    var value = $(this).val().toLowerCase();
    $("#aggs-description li").filter(function() {
      $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
    });
  });
});

$(document).ready(function(){
  $("#input-applications").on("keyup", function() {
    var value = $(this).val().toLowerCase();
    $("#application-table tr").filter(function() {
      $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
    });
  });
});